import React from "react";
import "./footer.css";
import { AiOutlineGithub } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { SiDiscord } from "react-icons/si";
import { IoLogoTwitter } from "react-icons/io";
import footerBackground from "../../assets/images/footer.png";

const footer = () => {
  return (
    <div
      data-aos="fade-up"
      className="cryptio__footer w-screen bg-no-repeat"
      style={{
        backgroundImage: `url(${footerBackground})`,
        backgroundSize: "cover",
      }}
    >
      <div className="cryptio__footer-head">
        <div className="cryptio__footer-1">
          <h2 data-aos="fade-up" className="scotia text-3xl">
            <span>LAUNCHPAD &amp;</span> MEIJI
          </h2>
          <p
            data-aos="fade-up"
            className="orionis opacity-70 text-sm"
            style={{ fontFamily: "Orionis" }}
          >
            MeijiDAO is a Blockchain focused Incubator and Launchpad. By staking
            $MEIJI, you become eligible to buy presale tokens before everyone
            else, giving you the edge in the booming crypto era!
          </p>
        </div>
        <div className="cryptio__footer-main">
          <div className="cryptio__footer-2">
            <h2 data-aos="fade-up" className="quest">
              ABOUT
            </h2>
            <ul data-aos="fade-up" className="orionis opacity-70">
              <li>
                <a href="https://launchpad.meijidao.io">main website </a>
              </li>
              {/* <li>
                <a href="#">privacy </a>
              </li>
              <li>
                <a href="#">terms services </a>
              </li>
              <li>
                <a href="#">overview </a>
              </li> */}
            </ul>
          </div>
          <div className="cryptio__footer-3">
            <h2 data-aos="fade-up" className="quest">
              LINKS
            </h2>
            <ul data-aos="fade-up" className="orionis opacity-70">
              <li>
                <a href="https://twitter.com/MeijiToken">Twitter </a>
              </li>
              <li>
                <a href="https://t.me/MeijiDAO">Telegram </a>
              </li>
              {/* <li>
                <a href="#">News </a>
              </li> */}
            </ul>
          </div>
          <div className="cryptio__footer-4">
            <h2 data-aos="fade-up" className="quest">
              CONTACT
            </h2>
            <ul data-aos="fade-up" className="orionis opacity-70">
              {/* <li>
                <a href="#">email</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="cryptio__footer-copy">
        <p className="roboto opacity-70">
          <span>MeijiDAO &amp;</span> is a registered of MeijiDAO &amp; INC. All
          Right Reserved
        </p>
        <p className="roboto opacity-70" style={{ fontFamily: "Orionis" }}>
          All logos are registered trademarks of their respective owners. All
          contents of this documents, unless otherwise credited, are copyright ©{" "}
          <span>2022 MeijiDAO</span>
        </p>
      </div>
    </div>
  );
};

export default footer;
