import useContractReader from "./ContractReader";

/** Returns total supply of tokens for project with `projectId`. */
export function useFarmUserInfo(farm, account) {
  return useContractReader({
    contract: farm,
    contractName: "farm",
    functionName: "userDeposits",
    args: [account],
  });
}

export function useFarmPendingRewards(farm, account) {
  return useContractReader({
    contract: farm,
    contractName: "farm",
    functionName: "calculate",
    args: [account],
  });
}

export function useRewardPerMinute(farm) {
  const rewPerBlock = useContractReader({
    contract: farm,
    contractName: "farm",
    functionName: "rewPerBlock",
    args: [],
  });

  const blocksPerHour = useContractReader({
    contract: farm,
    contractName: "farm",
    functionName: "blocksPerHour",
    args: [],
  });

  return {
    rewPerBlock,
    blocksPerHour,
  };
}

export function useTotalParticipants(farm) {
  return useContractReader({
    contract: farm,
    contractName: "farm",
    functionName: "totalParticipants",
    args: [],
  });
}

export function useTotalRewards(farm) {
  return useContractReader({
    contract: farm,
    contractName: "farm",
    functionName: "totalReward",
    args: [],
  });
}

export function useCurrentBlock(farm) {
  return useContractReader({
    contract: farm,
    contractName: "farm",
    functionName: "currentBlock",
    args: [],
  });
}

export function useLockDuration(farm) {
  return useContractReader({
    contract: farm,
    contractName: "farm",
    functionName: "lockDuration",
    args: [],
  });
}
