import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselItem from "./CarouselItem";
import heroCarouselBorder from "../../assets/images/heroCarouselBorder.png";
import heroCarouselImg1 from "../../assets/images/heroCarouselImg.png";
import carouselLeft from "../../assets/images/carouselLeft.png";
import carouselRight from "../../assets/images/carouselRight.png";
import carouselLeftLight from "../../assets/images/carouselLeftLight.png";
import carouselRightLight from "../../assets/images/carouselRightLight.png";

const CarouselContainer = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 991 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <button
        className="hero-carousel-left absolute right-0 top-2/4 w-16 -translate-y-2/4 z-10 containerButton"
        onClick={() => onClick()}
      >
        <img
          className="h-full w-full object-contain"
          src={
            document.body.classList.contains("active")
              ? carouselRightLight
              : carouselRight
          }
          alt="Left button"
        />
      </button>
    );
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <button
        className="hero-carousel-right absolute left-0 top-2/4 w-16 -translate-y-2/4 containerButton"
        onClick={() => onClick()}
      >
        <img
          className="h-full w-full object-contain"
          src={
            document.body.classList.contains("active")
              ? carouselLeftLight
              : carouselLeft
          }
          alt="Right button"
        />
      </button>
    );
  };

  return (
    <div
      data-aos="fade-up"
      id="carouselContainer"
      className="relative  p-7 bg-no-repeat  carousel carouselBorders"
      style={{
        zIndex: 0,
        backgroundImage: `url(${heroCarouselBorder})`,
        backgroundSize: "100% 100%",
        padding: "30px",
      }}
    >
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        showDots={true}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        removeArrowOnDeviceType={["mobile"]}
      >
        <CarouselItem img={heroCarouselImg1} />
      </Carousel>
    </div>
  );
};

export default CarouselContainer;
